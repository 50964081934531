@import "variable.scss";

.custom-proficiencies-header {
    margin-bottom: 3%;
    color: $nav_blue;
    padding-bottom: 10px;
    border-bottom: solid 1px;
}

.custom-proficiencies-content-row {
    min-height: 400px;

    .card {
        border: none;

        .card-body {
            font-weight: bold;

            .custom-profiency-item {
                margin-top: 1%;
                margin-bottom: 1%;
                color: $nav_blue;
                max-width: 75%;

                .custom-star-container {
                    @include size_viewport(lg) {
                        display: inline-block;
                        float: right;
                        margin-top: 1%;
                    }

                    @include size_viewport(not-desktop) {
                        display: none;
                    }
                }

                .custom-text-container {
                    @include size_viewport(lg) {
                        display: inline-block;
                    }

                    @include size_viewport(not-desktop) {
                        display: block;
                    }
                }
            }
        }
    }

    ul.nav {
        border-bottom: none;
    }

    .nav-item {
        font-weight: bold;
        font-size: 20px;

        a.nav-link {
            font-size: 18px;
            padding-top: 12px;
            padding-bottom: 12px;
            color: $nav_blue;
        }

        a.nav-link:hover {
            border: none;
        }

        a.active {
            background-color: $nav_blue;
            color: $nav_orange;
        }
    }
}

i.custom-circle {
    float: right;
    color: $nav_orange;
}

i.custom-circle-empty {
    float: right;
    color: $nav_orange;
}