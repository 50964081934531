@import "variable.scss";

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

nav.custom-navbar {
    @include size_viewport(lg) {
        display: none;
    }

    color: $nav_orange;
    background-color: $nav_blue;

    a.navbar-brand {
        font-weight: bold;
    }

    a {
        color: $nav_orange !important;
    }

    a:active {
        color: $nav_orange !important;
    }

    a:focus {
        color: $nav_orange !important;
    }

    a:hover {
        color: $nav_orange_hover !important;
    }
}

.custom-toggler.navbar-toggler {
    border-color: rgb(222,94,0);
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(222,94,0, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}