@import "variable.scss";

.custom-experience-header {
    margin-bottom: 3%;
    color: $nav_blue;
    padding-bottom: 10px;
    border-bottom: solid 1px;
}

.custom-experience-content-row {
    min-height: 300px;

    h4 {
        color: $nav_blue;
    }

    ul.nav {
        border-bottom: none;
    }

    .nav-item {
        font-weight: bold;
        font-size: 20px;

        a.nav-link {
            font-size: 18px;
            padding-top: 12px;
            padding-bottom: 12px;
            color: $nav_blue;
        }

        a.nav-link:hover {
            border: none;
        }

        a.active {
            background-color: $nav_blue;
            color: $nav_orange;
        }
    }
}