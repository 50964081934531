@import "variable.scss";

.custom-content-container {
    margin-left: 2%;
    
    p {
        color: $nav_blue;
    }

    h2 {
        color: $nav_blue;
    }
}

.custom-nav-row {
    width: 100%;
}

.custom-page-container {
    padding-left: 0px;
    padding-right: 0px;
}

p.custom-content-paragraph {
    width: 70%;
}