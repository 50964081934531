﻿$site_white: #fff;
$nav_blue: #1b2746;
$nav_orange: #de5e00;
$nav_orange_hover: #ec8b43;

@mixin size_viewport($breakpoint) {
    @if $breakpoint == "xsm" {
        @media (max-width: 480px) {
            @content;
        }
    }
    @else if $breakpoint == "sm" {
        @media (max-width: 767px) {
            @content;
        }
    }
    @else if $breakpoint == "md" {
        @media (min-width: 768px) and (max-width: 959px) {
            @content;
        }
    }
    @else if $breakpoint == "not-desktop" {
        @media (max-width: 959px) {
            @content;
        }
    }
    @else if $breakpoint == "lg" {
        @media (min-width: 960px) {
            @content;
        }
    }
}