@import "variable.scss";

.custom-education-header {
    margin-bottom: 1%;
    color: $nav_blue;
    padding-bottom: 10px;
    border-bottom: solid 1px;
}

.custom-education-content-row {
    .card {
        border: none;
    }

    h5 {
        color: $nav_blue;
    }
}
