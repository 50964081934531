@import "variable.scss";

.custom-nav-column {    
    a {
        text-decoration: none;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: $nav_orange;
        display: block;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    a:hover {
        color: $nav_orange_hover;
    }

    @include size_viewport(not-desktop) {
        display: none !important;
    }

    background-color: $nav_blue;
    height: 100vh;

    .profile-image {
        text-align: center;

        .profile-image-content {
            display: inline-block;
            content: url("../resource/ResumePicture.png");
        }
    }

    .bottom-items {
        bottom: 0;
        position: absolute;
        margin-left: -3%;
        font-size: 12px;
        color: $nav_orange;
        font-weight: bold;
    }
}

.custom-center-align-column {
    display: flex;
    align-items: center; /*Aligns vertically center */
    justify-content: center; /*Aligns horizontally center */
}